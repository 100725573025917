import { springTimePalette } from './stPalette';

export const springtimeTheme = {
  global: {
    focus: {
      border: {
        color: 'transparent',
      },
      outline: {
        color: springTimePalette['brand-600'],
        size: '4px',
      },
    },
    colors: {
      brand: springTimePalette['brand-600'],
      brandText: springTimePalette.white,
      brandHover: springTimePalette['brand-800'],
      brandHoverText: springTimePalette.white,
      brandActive: springTimePalette['brand-500'],
      brandActiveText: springTimePalette.white,

      secondary: springTimePalette['brand-100'],
      secondaryText: springTimePalette['brand-600'],
      secondaryHover: springTimePalette['brand-100'],
      secondaryHoverText: springTimePalette['brand-800'],
      secondaryActive: springTimePalette['brand-100'],
      secondaryActiveText: springTimePalette['brand-500'],

      successBg: springTimePalette['green-200'],
      successFg: springTimePalette['green-800'],
      infoBg: springTimePalette['purple-100'],
      infoFg: springTimePalette['purple-900'],
      defaultBg: springTimePalette['gray-100'],
      defaultFg: springTimePalette['gray-600'],
      warningBg: springTimePalette['yellow-200'],
      warningFg: springTimePalette['yellow-800'],
      errorBg: springTimePalette['red-200'],
      errorFg: springTimePalette['red-800'],

      error: springTimePalette['red-600'],
    },
  },

  text: {
    small: { size: '1rem', height: '1.5rem' },
    large: { size: '1.5rem', height: '1.725rem' },
  },
  tabs: {
    extend: {
      width: '100%',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '& > div[role="tablist"]': {
        marginBottom: '3rem',
        width: '100%',
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '& > div[role="tablist"] > div': {
        justifyContent: 'flex-start',
      },
    },
    header: {
      extend: {
        gap: '0',
      },
      border: {
        color: '#D0D3DB',
        side: 'bottom',
        size: '1px',
        marginTop: '-1px',
      },
    },
  },
  layer: {
    overlay: {
      background: springTimePalette['shadow-rgba'],
    },
  },
  tab: {
    color: 'text',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:focus': {
      borderRadius: '.5rem',
      boxShadow: 'none',

      // eslint-disable-next-line @typescript-eslint/naming-convention
      '&:after': {
        boxSizing: 'content-box',
        display: 'block',
        content: '""',
        position: 'absolute',
        padding: 4,
        border: `3px solid ${springTimePalette['brand-900']}`,
        width: '100%',
        height: '100%',
        left: -7,
        top: -7,
        borderRadius: 'inherit',
      },
    },
    extend: {
      padding: '1rem',
      margin: '0',
      marginBottom: '-1px',
      borderTopRightRadius: '0.5rem',
      borderTopLeftRadius: '0.5rem',
    },
    border: {
      side: 'bottom',
      color: 'transparent',
      size: '2px',
      active: {
        color: springTimePalette['brand-600'],
      },
      hover: {
        color: springTimePalette['brand-800'],
      },
    },
    active: {
      color: springTimePalette['brand-600'],
      background: 'secondary',
      extend: {
        borderBottom: `2px solid ${springTimePalette['brand-600']}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
      },
    },
    hover: {
      color: springTimePalette['brand-800'],
      background: springTimePalette['brand-50'],
      extend: {
        borderBottom: `2px solid ${springTimePalette['brand-800']}`,
      },
    },
  },
  anchor: {
    color: 'brand',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '& *': {
      stroke: 'brand !important',
    },
    hover: {
      extend: `
        color: ${springTimePalette['brand-800']};
      `,
    },
    extend: `
      &:active {
        color: ${springTimePalette['brand-900']} !important;
      }
    `,
  },
  modal: {
    borderRadius: '10px',
    boxShadow: '0px 0px 32px rgba(44, 4, 97, 0.16)',
    color: springTimePalette.black,
    backgroundColor: springTimePalette.white,
    justifyContent: 'space-between',
    overflowY: 'auto',
    padding: 'none',
  },
  modalHeader: {
    paddingTop: '2rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    borderBottom: `1px solid ${springTimePalette['gray-200']}`,
  },
  modalBody: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingBottom: '2rem',
  },
  modalFooter: {
    padding: '2rem',
    flexDirection: 'row',
    borderTop: `1px solid ${springTimePalette['gray-200']}`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '& > *': {
      paddingLeft: '0.75rem',
    },
  },
  radiusPalette: {
    modal: '1rem',
  },
  paddingPalette: {
    modal: '0',
  },
  palette: springTimePalette,
  sidebar: {
    minWidth: '273px',
  },
  mainContent: {
    maxWidth: '960px',
  },
  filterPills: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
  },
  filterTogglePill: ({
    theme: {
      global: { borderSize, colors: themeColors },
      palette,
    },
    isDisabled,
    isSelected,
    focusIndicator,
  }: {
    theme: any;
    isDisabled: boolean;
    isSelected: boolean;
    focusIndicator: boolean;
  }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: `${borderSize.xsmall}`,
    borderStyle: 'solid',
    background: isDisabled ? palette['gray-50'] : isSelected ? palette['brand-50'] : themeColors.white,
    borderColor: isDisabled ? palette['gray-100'] : isSelected ? themeColors.brand : palette['gray-300'],
    padding: '.25rem .5rem',
    borderRadius: '1rem',
    fontSize: '1rem',
    color: isDisabled ? palette['gray-300'] : isSelected ? themeColors.brand : themeColors.black,
    fontWeight: 500,
    outline: focusIndicator ? `${themeColors.brand} solid ${borderSize.medium}` : undefined,
  }),
};
